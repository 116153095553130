<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="tituloModal"
      :closeOnBackdrop="false"
      color="dark"
      :show.sync="AddModal"
    >
      <CRow class="mt-2">
        <CCol sm="12" lg="12" xl="12">
          <CInput
            addLabelClasses="required text-right"
            v-uppercase
            :label="$t('label.name')+' '+('(ES)')"
            :placeholder="$t('label.packagingName')+' '+('(ES)')"
            :horizontal="{label:'col-sm-12 col-lg-3 col-xl-3', input:'col-sm-12 col-lg-8 col-xl-8'}"
            :invalid-feedback="errorMessage($v.PackagingNameEs)"
            maxlength="20"
            v-model="$v.PackagingNameEs.$model"
            :is-valid="hasError($v.PackagingNameEs)"
          />
        </CCol>
        <CCol sm="12" lg="12" xl="12">
          <CInput
            addLabelClasses="required text-right"
            v-uppercase
            :label="$t('label.name')+' '+('(EN)')"
            :placeholder="$t('label.packagingName')+' '+('(EN)')"
            :horizontal="{label:'col-sm-12 col-lg-3 col-xl-3', input:'col-sm-12 col-lg-8 col-xl-8'}"
            :invalid-feedback="errorMessage($v.PackagingNameEn)"
            maxlength="20"
            v-model="$v.PackagingNameEn.$model"
            :is-valid="hasError($v.PackagingNameEn)"
          />
        </CCol>
        <CCol sm="12" lg="12" xl="12">
          <CInput
            ref="weightInput"
            :label="this.$t('label.weight') +  ' (KG)'"
            :horizontal="{label:'col-sm-12 col-lg-3 col-xl-3', input:'col-sm-12 col-lg-8 col-xl-8'}"
            placeholder="0"
            addLabelClasses="text-right"
            v-model.trim="Weight"
            v-money="measure"
            @focus="$v.Weight.$touch()"
            maxlength="13"
            :is-valid="hasError($v.Weight)"
          >
            <template #invalid-feedback>
              <div class="invalid-feedback" v-if="$v.Weight.$error">
                {{$t('label.ValueNotNegative')}}
              </div>
            </template>
          </CInput>
        </CCol>
        <CCol sm="12" lg="12" xl="12" v-if="actualizar">
          <CSelect
            :label="$t('label.status')"
            addLabelClasses="text-right"
            :horizontal="{label:'col-sm-12 col-lg-3 col-xl-3', input:'col-sm-12 col-lg-8 col-xl-8'}"
            v-model="Status"
            :value.sync="Status"
            :is-valid="Status"
            :options="selectOptions"
          />
        </CCol>
      </CRow>
      <template #footer>
        <CButton color="add" :disabled="isSubmit" @click="evaluaStatus">
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>
        <CButton color="wipe" :disabled="isSubmit" @click="AddModal = false">
          <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>

<script>
import UpperCase from "@/_validations/uppercase-directive";
import EmbalajeVal from "@/_validations/embalaje/embalajeVal";
import General from "@/_mixins/general";
import { VMoney, Money } from "v-money";
import { NumberFormater } from '@/_helpers/funciones';

//METODOS
function resetComponent() {
  this.PackagingNameEs = "";
  this.PackagingNameEn = "";
  let inputWeight = this.$refs.weightInput.$el.querySelector("input");
  inputWeight.value = '';
  this.Status= true,
  this.StatusOrigin= true,
  this.$nextTick(() => {
    this.$v.$reset();
  });
}
function guardar() {
   try {
      this.Loading = true;
      this.isSubmit = true;
      this.$v.$touch();

      if (this.$v.$error) {
        throw this.$t('label.errorsPleaseCheck');
      }

      let res = [];
      let metodo = "";
      let ruta = "";
      let PackagingJson = [];
      //ASIGNACION DE VARIABLES PARA GUARDAR
      if (this.actualizar) {
        PackagingJson = {
          PackagingId: this.PackagingId,
          PackagingNameEs: this.PackagingNameEs,
          PackagingNameEn: this.PackagingNameEn,
          Weight: NumberFormater.setNum(this.Weight),
          Status: this.Status,
        };
        metodo = "PUT";
        ruta = "Packaging-update";
      } else {
        PackagingJson = {
          PackagingNameEs: this.PackagingNameEs,
          PackagingNameEn: this.PackagingNameEn,
          Weight: NumberFormater.setNum(this.Weight),
        };
        metodo = "POST";
        ruta = "Packaging-insert";
      }
      //ENVIANDO POST PARA GUARDAR O ACTUALIZAR
      this.$http
        .ejecutar(metodo, ruta, PackagingJson, {
          root: "PackagingJson",
        })
        .then((response) => {
          res = [...response.data.data];
          this.resetComponent();
          this.$notify({
            group: "container",
            title: "¡Exito!",
            text: res[0].Response,
            type: "success",
          });
          this.Loading = false;
          this.isSubmit = false;
          this.$emit("child-refresh", true);
          this.AddModal = false;
        })
        .catch((err) => {
          this.$notify({
            group: "container",
            title: "¡Error!",
            text: err,
            type: "error",
          });
          this.Loading = false;
          this.isSubmit = false;
        });
      } catch (e) {
        this.Loading = false;
        this.isSubmit = false;
          this.$notify({
            group: "container",
            title: "¡Error!",
            text: e,
            type: "error",
          });
      }
}

function evaluaStatus() {
  if (this.Status !== this.StatusOrigin) {   
      this.$swal
        .fire(this.alertProperties({
            text: `${this.$t('label.changeStatusQuestion')+'?'}`,
        }))
      .then((result) => {
        if (result.isConfirmed) {
          this.guardar();
        }
      });
  } else this.guardar();
}
async function checkInputs(value) {
  if(value){
    this.$v.$touch();
}

}

//COMPUTED
function isDisabled() {
  return this.$v.$invalid;
}
 function selectOptions() {
    return [
      { 
          value: true, 
          label: this.$t('label.ACTIVO')
      },
      { 
          value: false, 
          label: this.$t('label.INACTIVO')
      },
    ];
 }
//DATA
function data() {
  return {
    // VARIABLES
    AddModal: false,
    Loading: false,
    Status: true,
    StatusOrigin: true,
    actualizar: false,
    tituloModal: "",
    isSubmit: false,
    //MODELO
    PackagingNameEs: "",
    PackagingNameEn: "",
    Weight: "",
    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
    },
  }
}
export default {
  name: "modal-packaging",
  mixins: [General],
  data,
  props: {
    modal: null,
  },
  components: {
    Money,
  },
  directives: {
    uppercase: {
      bind(el, _, vnode) {
        el.addEventListener('input', (e) => {
            e.target.value = e.target.value.toUpperCase()
            vnode.componentInstance.$emit('input', e.target.value.toUpperCase())
        });
      }
    },
    money: VMoney
  },
  validations: EmbalajeVal,
  watch: {
    modal: async function () {
      if (this.modal) {
        this.resetComponent();
        this.AddModal = true;
        if (this.modal == true) {
          this.tituloModal = this.$t('label.nuevo') + this.$t('label.packaging');
          this.actualizar = false;
        } else {
          this.actualizar = true;
          let _lang = this.$i18n.locale;
          let title = _lang=='en' ? this.modal.PackagingNameEn : this.modal.PackagingNameEs;
          this.tituloModal = this.$t('label.edit') + this.$t('label.packaging') +': ' + title;
          this.PackagingId = this.modal.PackagingId;
          this.PackagingNameEs = this.modal.PackagingNameEs;
          this.PackagingNameEn = this.modal.PackagingNameEn;
          this.Weight = this.modal.Weight ? this.modal.Weight.toFixed(2) : '';
          inputWeight.value = this.Weight;
          this.Status = this.modal.FgActPackaging;
          this.StatusOrigin = this.modal.FgActPackaging;
          await this.checkInputs(this.actualizar);
          this.$v.$touch();

        }
        this.$emit("closeModal");
      }

    },
  },
  methods: {
    evaluaStatus,
    resetComponent,
    guardar,
    checkInputs,
  },
  computed: {
    isDisabled,
    selectOptions,
    
  },
};
</script>
<style scoped></style>
