import { required, maxLength, } from "vuelidate/lib/validators";
import { nameLength } from '@/_validations/validacionEspeciales';
import { NumberFormater } from '@/_helpers/funciones';

export default () => {
    return {
        PackagingNameEs: { required,  nameLength, maxLength: maxLength(20) },
        PackagingNameEn: { required, nameLength, maxLength: maxLength(20) },
        Weight: { ValueNotNegative(Value) {
            return NumberFormater.setNum(Value) >= 0;
        } },
        Status: { },
    }
}